import { ConexionBase } from "@victorequena22/conneccion";
const http_ref: string = Function('return http_ref')();

class MT extends ConexionBase {
    clase = 'Web/Metricas';
    uuid: string;
    constructor() {
        super();
        let uuid = localStorage.getItem("uuid");
        if (uuid === null) {
            uuid = crypto.randomUUID();
            if (uuid === null) {
                localStorage.setItem("uuid", uuid);
            }
        }
        this.uuid = uuid;
    }
    setMetricas() {
        if ((new URL(document.location.href)).hostname !== 'localhost')
            this.postProxi('/setMetricas', { uuid: this.uuid, http_ref })
    }
    recorrido(lugar: string, codigo: string) {
        if ((new URL(document.location.href)).hostname !== 'localhost')
            this.postProxi('/recorrido', { uuid: this.uuid, lugar, codigo, http_ref })
    }
    llegada(lugar: string) {
        if ((new URL(document.location.href)).hostname !== 'localhost')
            this.postProxi('/recorrido', { uuid: this.uuid, lugar, http_ref })
    }
    setMetricasNav(model: string, id: number) {
        if ((new URL(document.location.href)).hostname !== 'localhost')
            this.postProxi('/setMetricasNav', { uuid: this.uuid, model, id, http_ref })
    }
}
export default function Metricas() {
    return new MT();
}