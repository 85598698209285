import { Row, Container, Card, Col } from 'react-bootstrap';
import { Zoom } from '../../Components/Zoom';
import { Html } from '../../Components/Html';
import { useState } from 'react';
import { getImagenURL } from '../../Utils/Imagen';
export default function CardCargos({ data: { itens, props } }: any) {
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState(itens[0].id);
    const { color } = props
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Zoom color={color} imgs={itens.map((i: any) => { return { id: i.id, src: i.props.img } })}
            select={select} setSelect={setSelect} open={open} setOpen={setOpen} />
        <Container><Row className='justify-content-center'>
            {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} click={() => { setOpen(true); setSelect(i.id) }} />)}
        </Row></Container>
    </Row>
}
function Itens({ iten: { id, html, props: { img, color, back } }, click }: any) {
    const style = { background: back, color };
    return <Col className='m-0 p-2' xs={6} lg={4}>
        <Card style={{ height: '100%' }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img style={{ aspectRatio: '1' }} onClick={click} variant="top" src={getImagenURL(img, 2)} alt={`cargos ${id}`} />
            <Html className='card-body' style={style} html={html} />
        </Card>
    </Col >
}